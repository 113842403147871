import Vue from 'vue'
import store from '../../../store'
import i18n from '@/modules/i18n'
import { setupAxios } from '@/modules/config/axios'

import BookingServiceMobileDropdown from '@/components/layout/sidebar/booking-service-mobile-dropdown.vue'

setupAxios()

const bookingService = new Vue({
  el: '#booking-service-mobile-dropdown',
  store,
  i18n,
  components: {
    BookingServiceMobileDropdown
  }
})

window.vue = bookingService
