<template lang='pug'>
  .booking-services
    li.nav-item
      .booking-service-links
        a.nav-link.title(@click.stop='toggleBeautyServiceNav')
          span.link-icon.svg-icon.calendar
          span.link-text(v-text="$t('retailStore.beautyServices')")
          span(:class='showBeautyServiceNav ? "minus-icon" : "plus-icon"')

        transition(name='slide-toggle')
          ul.booking-service-nav(v-if='showBeautyServiceNav')
            template(v-if='isLoading')
              .booking-service-loader
                .loader-spinner

            template(v-else)
              li.nav-item(v-for='beautyServiceLink in beautyServiceLinks')
                a.nav-link.child(:href='beautyServiceLink.path')
                 span.link-text(v-text='beautyServiceLink.label')

    li.nav-item
      .booking-service-links
        a.nav-link.title(@click.stop='toggleStoreEventNav')
          span.link-icon.svg-icon.store-location
          span.link-text(v-text="$t('retailStore.storeEvent')")
          span(:class='showStoreEventNav ? "minus-icon" : "plus-icon"')

        transition(name='slide-toggle')
          ul.booking-service-nav(v-if='showStoreEventNav')
            template(v-if='isLoading')
              .booking-service-loader
                .loader-spinner

            template(v-else)
              li.nav-item(v-for='storeEventLink in storeEventLinks')
                a.nav-link.child(:href='storeEventLink.path')
                 span.link-text(v-text='storeEventLink.label')
                 span.link-description(v-text='storeEventLink.description')
</template>

<script>
import { bookingServiceLinks } from '@/mixins/bookingServiceLinksUtil.js'

export default {

  mixins: [
    bookingServiceLinks
  ],
  data () {
    return {
      showBeautyServiceNav: false,
      showStoreEventNav: false
    }
  },

  created () {
    window.addEventListener('click', () => {
      this.showBeautyServiceNav = false
      this.showStoreEventNav = false
    })
  },

  destroyed () {
    window.removeEventListener('click', () => {
      this.showBeautyServiceNav = false
      this.showStoreEventNav = false
    })
  },

  methods: {
    toggleBeautyServiceNav () {
      this.fetchResources()
      this.showBeautyServiceNav = !this.showBeautyServiceNav
    },
    toggleStoreEventNav () {
      this.fetchResources()
      this.showStoreEventNav = !this.showStoreEventNav
    }
  }
}
</script>

<style scoped lang='scss'>
  .slide-toggle-enter-active {
    transition: max-height .8s ease-in;
  }

  .slide-toggle-leave-active {
    transition-property: max-height;
    transition-duration: .8s;
    transition-timing-function: ease;
    transition-delay: -0.3s;
  }

  .slide-toggle-enter-active {
    max-height: 3000px;
  }

  .slide-toggle-enter,
  .slide-toggle-leave-active {
    max-height: 0;
  }

  .slide-toggle-leave {
    max-height: 3000px;
  }

  %text-icon {
    @include font-weight-medium;
    color: $grey;
    font-size: 24px;
    float: right;
    margin-top: 8px;
  }

  .plus-icon:after {
    content: '+';
    @extend %text-icon;
  }

  .minus-icon:after {
    content: '\2013';
    @extend %text-icon;
  }

  .nav-item {
    max-width: 100%;
    overflow-y: hidden;
    @include font-weight-bold;
    font-size: 16px;
    text-transform: uppercase;
  }

  .booking-service-links {
    .link-icon {
      display: inline-block;
      vertical-align: bottom;
      height: 30px;
      width: 30px;
      background-size: 30px 30px;
      margin-right: 15px;
    }

    .link-text {
      display: inline-block;
      vertical-align: text-bottom;
      font-size: 16px;
      line-height: 19px;
    }

    .link-description {
      display: block;
      color: $grey-dark;
      font-size: 13px;
    }

    .nav-link {
      display: block;
      padding: 10px 20px;
    }

    .booking-service-nav {
      width: 100%;
      .nav-item {
        @include font-weight-book;
        font-size: 14px;
        text-transform: capitalize;

        .nav-link {
          margin-left: 20px;
        }

        .link-text {
          line-height: 10px;
        }
      }
    }
  }

  .booking-service-loader {
    width: 100%;
    display: flex;
    justify-content: center;

    .loader-spinner {
      height: 30px;
      width: 30px;
      border-width: 4px;
    }
  }

  .nav-link:hover {
    color: $black;
  }
</style>
